/*************************************
 *** Init functions js definitions ***
 *************************************/

$(document).ready(function () {

    //ajax add csrf token
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    
    //init Voucher Form Validation
    initVoucherValidation();
    
    //init pages lazy loading
    initLazyLoading();

    //init File Type Input modifier
    initFileTypeInput();
    
    //init TableData
    initDataTable();

    //init Semantic Table (e.g.: for sortable option)
    initSemanticTable();
    
    //init semantic ui dropdown
    initDropdown();

    //init semantic menu dropdown
    initMenuDropdown();
    
    //init semantic ui checkbox
    initCheckbox();

    //init Semantic UI tabs
    initTabs();
    
    //init custom Semantic UI Product tabs
    initProductTabs();
    
    //init coolest dropdown selector
    initChosen();
    
    //init TinyMCE text edior
    initTinyMce();
    
    //init UI form validation
    initFormValidation();
    
    //init search Products
    initProductSearch();

    //init Schedule product search
    initScheduleProductSearch();

    //init users search
    initUserSearch();
    
    //init product categories treeview accordion
    initCategoriesAccordion();
        
    toastr.options = {
        "closeButton": false,
        "debug": false,
        "newestOnTop": true,
        "progressBar": true,
        "positionClass": "toast-top-full-width",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "10000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
});

function initLazyLoading()
{
    $(window).load(function() {
        $("#loader").dimmer('show');
        $("#loader").dimmer('hide');
    });
}

function initFileTypeInput(){
    $('.ui.upload.input input.replacer, .ui.upload.input .ui.choose.button')
        .on('click', function(e) {
            $(this).parent().find('input:file').click();
        }
    );

    $('.ui.upload.input input:file, .ui.upload.action.input')
        .on('change', function(e) {
            var name = e.target.files[0].name;
            $(this).parent().find('input.replacer').val(name);
        }
    );
}

function initDataTable()
{
    $('.js_myTable').DataTable({
        "pageLength": 25
    });
}

function initSemanticTable()
{
    $('table.ui.sortable.table').tablesort();
}

function initDropdown()
{
    $('.ui.dropdown:not(.my_chosen)').dropdown();
}

function initMenuDropdown(){
    $('.main.menu .ui.dropdown').dropdown({
        on: 'hover'
    });
}

function initCheckbox()
{
    $('.ui.checkbox:not(.main_image.radio)').checkbox();
}

function initTabs()
{
    $('.tabular.menu .item').tab();
}

function initProductTabs()
{
    $('.product.tabular.menu .item').tab({
        history : true,
        historyType : 'hash'
    });
}

function initChosen()
{
    $(".my_chosen").chosen({
        disable_search_threshold: 10,
        no_results_text: "Oops, nothing found!",
        include_group_label_in_selected: true,
        search_contains: true,
        allow_single_deselect: true,
        width: "100%",
        scroll_to_highlighted: false //fixing scrolling highlight bug by preventing automatic vertical scroll
    });
}

function initTinyMce()
{
    tinymce.init({
        //the class selector used for init
        selector: '.tinymce',
        //height: 500,
        // Include TinyMCE general plugins
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen autoresize',
            'insertdatetime media table contextmenu paste code'
        ],
        apply_source_formatting : true,
        autoresize_bottom_margin: 0,
        //limit the toolbar with specific options
        toolbar: 'insertfile undo redo | styleselect | bullist numlist | link | code',
        //remove the menu bar
        menubar: false,
        //remove the bottom status bar
        statusbar: false
    });
}

function initFormValidation()
{
    $('.ui.form')
        .form({
          on: 'blur',
          fields: {
            product_title: {
              identifier: 'product_title',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter product title'
                }
              ]
            },
            // sku_manufacturer: {
            //   identifier: 'sku_manufacturer',
            //   rules: [
            //     {
            //       type   : 'empty',
            //       prompt : 'Please enter SKU manufacturer'
            //     }
            //   ]
            // },
            product_sku_merchant: {
              identifier: 'product_sku_merchant',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter SKU merchant'
                }
              ]
            },
            product_sku: {
              identifier: 'product_sku',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter SKU'
                }
              ]
            },
            product_price: {
              identifier: 'product_price',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter price'
                }
              ]
            },
            product_cost: {
              identifier: 'product_cost',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter cost'
                }
              ]
            },
            stock_level: {
              identifier: 'stock_level',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter stock level'
                }
              ]
            },
            supplier_id: {
              identifier: 'supplier_id',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please select supplier'
                }
              ]
            },
            report_format: {
              identifier: 'report_format',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please select report format'
                }
              ]
            },
            name: {
              identifier: 'name',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter name'
                }
              ]
            },
            display_name: {
              identifier: 'display_name',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter display name'
                }
              ]
            },
            product_quantity: {
              identifier: 'product_quantity',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter quantity'
                }
              ]
            },
            code: {
              identifier: 'code',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter code'
                }
              ]
            },
            reference: {
              identifier: 'reference',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please select reference'
                }
              ]
            },
            type_id: {
              identifier: 'type_id',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please select type'
                }
              ]
            },
            role_id: {
              identifier: 'role_id',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please select role'
                }
              ]
            },
            email: {
              identifier: 'email',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter email'
                }
              ]
            },
            tag: {
              identifier: 'tag',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter tag name'
                }
              ]
            },
            replacement: {
              identifier: 'replacement',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter replacement name'
                }
              ]
            }
         
          
        }
    });
    
}

//search product after title or sku
function initProductSearch()
{
    $('.ui.product.search')
      .search({
        apiSettings: {

            url: '/products-section/products/search?query={query}'         
        },
        fields: {
            results : 'results',
            title:'title',
            sku:'sku',
            sku_merchant: 'sku_merchant'
        },
        maxResults : false,
        onSelect:function(result, response){
            if($('.ui.product.search').hasClass('tag')){
                $('#tag_form #product_id').val(result.id);
            }else  {
                addProductToOrder(result.id); //append product row
            }

            return 'default';
        },
        minCharacters : 3
      })
    ;
}

//search product by supplier after title or sku (used in report schedule)
function initScheduleProductSearch()
{
    var supplier_id = $('#supplier_id option:selected').val();
    
    $('.ui.schedule.search')
      .search({
        apiSettings: {
            url: '/products-section/products/search?query={query}&supplier_id='+supplier_id
        },
        fields: {
            results : 'results',
            title:'title',
            sku:'sku',
            sku_merchant: 'sku_merchant'
        },
        maxResults : false,
        onSelect:function(result, response){
            $('#schedules_form #product_id').val(result.id);

            return 'default';
        },
        onResultsClose :function(){ return 'default';},
        cache:false,
        minCharacters : 3
      })
    ;
}

//search reference based on selected voucher rule type
function initVoucherReferenceSearch(id)
{
    $('#reference_'+id+' input').val('');
    
    var rule_type = $('#rule_form_'+id+' #rule_type option:selected').val();
    
    $('#reference_'+id)
      .search({
        apiSettings: {
            url: '/orders-section/vouchers/search?query={query}&rule_type='+rule_type
        },
        fields: {
            results : 'results',
            title:'title',
            sku:'sku',
            sku_merchant: 'sku_merchant'
        },
        maxResults : false,
        onSelect:function(result, response){
            $('#rule_form_'+id+' #reference_id').val(result.id);
            return 'default';
        },
        onResultsClose : function(){ return 'default';},
        cache : false,
        minCharacters : 3
      })
    ;
}

//Init the edit button for the selected product 
function initUndefinedProductEdit(supplier_id, product_id) {
    if(product_id != 0){
        //var base_url = window.location.protocol + "/" + window.location.host + "/";
        $('#unspec_category_'+supplier_id+ ' .actions').empty().append('<a class="ui blue button" href="/products-section/products/'+ product_id +'/edit#/details" target="_blank">Edit Product</a>');
    }
    else{
        $('#unspec_category_'+supplier_id+ ' .actions').empty();
    }
}


//search users
function initUserSearch()
{
    $('.ui.customer.search')
      .search({
        apiSettings: {
            url: '/users/search?query={query}'
        },
        fields: {
            results : 'results',
            id:'id',
            title: 'title',
            first_name:'first_name',
            last_name:'last_name',
            email:'email'         
        },
        maxResults : false,
        onSelect:function(result, response){
            
            $('#user_id').val(result.id);
            $('#user_email').val(result.email);
            getUserAddresses(result.id);
            return 'default';
        },
          onResultsClose :function(){ return 'default';},
          cache:false,
        minCharacters : 1
      })
    ;
    
}

function getUserAddresses(user_id){
    
    jQuery.ajax({
        method: "GET",
        url: "/users/addresses/" + user_id
       
    }).done(function (data) {
        
        toastr.clear();
        $("#billing_address_id").html(data).trigger('chosen:updated');
        $("#shipping_address_id").html(data).trigger('chosen:updated');
        //init the new added in DOM for selects 
//        initChosen();
    });
}

//init the product categories treeview accordion
function initCategoriesAccordion(){
    $('.categories.accordion').accordion({
        selector: {
            trigger: '.title .dropdown.icon'
        },
        exclusive: false
    });
    
    setActiveAccordion();
}

//set to active parents accordions of items active(checked checkbox)
function setActiveAccordion(){
    $('.categories.accordion .checkbox.checked').each(function(i, e){
        //set main accordion contents to active
        $(e).parents().closest('.accordion').parent().addClass('active');
        //set contents accordions to active
        $(e).parents().closest('.accordion').removeClass('hidden').addClass('active');
        //set contents accordions title to active
        $(e).parents().closest('.accordion').parent().prev('.title').addClass('active');
    });
}


//sort function used by listing tables
function sortBy(elem, link) {
    console.log(link);
    //event.preventDefault();
    $(elem).attr("href",link);
    
    console.log($(elem).attr('href'));
}