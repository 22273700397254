/*************************************
 *** Main JS functions definitions ***
 *************************************/

/*******************************
 GENERALS
 *******************************/
function imagePopup(type, id) {
    $('#' + type + '_image_modal_' + id)
            .modal({
                closable: true,
                onHidden: function () {
                    $('.modal').parent().removeClass('dimmer');
                }
            })
            .modal('show')
            ;
}


/*******************************
 Tags Section
 *******************************/
/*Save New Brand*/
function saveBrand()
{
    $('#brands_form').form('validate form');
    if ($('#brands_form').form('is valid')) {
        form = $('#brands_form').serialize();
        $.post('/products-section/brands', form, function (response) {
            $('#brands_listing tbody').prepend(response);
            //clear form inputs
            $('#brands_form')[0].reset();
            toastr.info('New brand created!');
        });
    }
}

/*Delete Brand*/
function deleteBrand(id)
{
    var answer = confirm("Are you sure you want to delete from the database?");
    if (answer)
    {
        $.ajax({
            url: '/products-section/brands/' + id,
            type: 'DELETE',
            success: function (data) {
                $('#brand_' + data).remove();
                toastr.info('Brand deleted!');
            }
        });
    }
}

/*******************************
 Tags Section
 *******************************/
/*Save New Tag*/
function saveTag()
{
    $('#tag_form').form('validate form');
    if ($('#tag_form').form('is valid')) {
        
        var form = $('#tag_form').serialize();

        $.post('/suppliers-section/tags', form, function (response) {
            $('#tags_listing tbody').prepend(response);
            //clear form inputs
            $('#tag_form')[0].reset();
            toastr.info('New tag created!');
        });
    }
}

/*Delete Tag*/
function deleteTag(id)
{
    var answer = confirm("Are you sure you want to delete from the database?");
    if (answer)
    {
        $.ajax({
            url: '/suppliers-section/tags/' + id,
            type: 'DELETE',
            success: function (data) {
                $('#tag_' + data).remove();
                toastr.info('Tag deleted!');
            }
        });
    }
}

/*******************************
 Terms & Conditions Section
 *******************************/
/*Delete Term & Condition*/
function deleteTermCondition(id)
{
    var answer = confirm("Are you sure you want to delete from the database?");
    if (answer)
    {
        $.ajax({
            url: '/suppliers-section/terms-conditions/' + id,
            type: 'DELETE',
            success: function (data) {
                $('#term_' + data).remove();
                toastr.info('Term & Condition deleted!');
            }
        });
    }
}

/*******************************
 Categories Section
 *******************************/
/*Save New Category*/
function saveCategory()
{
    $('#category_form').form('validate form');
    if ($('#category_form').form('is valid')) {
        form = $('#category_form').serialize();
        toastr.options.timeOut = 50000;
        toastr.info('Please wait, it may take a while...');
        
        $.post('/products-section/categories', form, function (response) {
            toastr.clear();
            toastr.options.timeOut = 5000; 
            toastr.info('New category created!');
            $('#categories_listing tbody').prepend(response);
            //clear form inputs
            $('#category_form')[0].reset();
            initDropdown();
        });
    }
}

/*Delete Category*/
function deleteCategory(id)
{
    var answer = confirm("Are you sure you want to delete from the database?");
    if (answer)
    {
        toastr.options.timeOut = 50000;
        toastr.info('Please wait, it may take a while...');
        $.ajax({
            url: '/products-section/categories/' + id,
            type: 'DELETE',
            success: function (data) {
                toastr.clear();
                toastr.options.timeOut = 5000; 
                toastr.info('Category deleted!');
                $('#category_' + data).remove();
            }
        });
    }

}

/*Delete Category Match*/
function deleteCategoryMatch(id)
{
    var answer = confirm("Are you sure you want to delete from the database?");
    if (answer)
    {
        $.ajax({
            url: '/products-section/matching/' + id,
            type: 'DELETE',
            success: function (data) {
                $('#category_matching_' + data).remove();
                toastr.info('Category Matching deleted!');
            }
        });
    }

}

function warningHomepageImage() {
    if ($("#set_category_homepage input[type='checkbox']").is(':disabled')) {
        alert('Please upload homepage banner first!');
    }
}

/*******************************
 Attributes Section
 *******************************/
/*Save Attribute*/
function saveAttribute()
{
    $('#attribute_form').form('validate form');
    if ($('#attribute_form').form('is valid')) {
        var form = $('#attribute_form').serialize();

        toastr.info('Please wait...');

        $.post('/products-section/attributes', form, function (response) {
            $('#attributes_listing tbody').prepend(response);
            //clear form inputs
            $('#category_form')[0].reset();
            toastr.info('New attribute created!');
        });
    }
}

/*Update Attribute*/
function updateAttribute(id)
{
    $('#attribute_form').form('validate form');
    if ($('#attribute_form').form('is valid')) {
        var form = $('#attribute_form').serialize();

        toastr.info('Please wait...');

        jQuery.ajax({
            method: "PUT",
            url: '/products-section/attributes/' + id,
            data: form}).done(function (response) {
            $('#attribute_form_block tbody').replaceWith(response);

            toastr.clear();
        });
    }
}

/*Add Attribute Option*/
function addAttributeOption(attribute_id)
{
    $.get('/products-section/attribute-options/create?attribute_id=' + attribute_id, function (response) {
        $('#attribute_options_block').prepend(response);
        initFormValidation();
    });
}

/*Save Attribute Option*/
function saveAttributeOption(id)
{
    $('#new_attribute_option_form').form('validate form');
    if ($('#new_attribute_option_form').form('is valid')) {
        var form = $('#new_attribute_option_form').serialize();

        toastr.info('Please wait...');

        $.post('/products-section/attribute-options', form, function (response) {
            toastr.clear();
            $('#option_block_' + id).replaceWith(response);
        });
    }
}

/*Update Attribute Option*/
function updateAttributeOption(id)
{

    var form = $('#attribute_option_form_' + id).serialize();

    toastr.info('Please wait...');

    jQuery.ajax({
        method: "PUT",
        url: '/products-section/attribute-options/' + id,
        data: form}
    ).done(function (response) {
        toastr.clear();
        $('#option_block_' + id).replaceWith(response);
    });
}

/*Delete Attribute Option*/
function deleteAttributeOption(id)
{
    if (!confirm('The option you intend to delete will be cleared also from product variations.\nProceed?'))
        return false;

    var form = $('#attribute_option_form_' + id).serialize();

    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: '/products-section/attribute-options/' + id,
        data: form}
    ).done(function (response) {
        toastr.clear();
        $('#option_block_' + id).remove();
    });
}

/*******************************
 Product Section
 *******************************/
function deleteProduct(id)
{
    if (!confirm('Are you sure to delete this product ?'))
        return false;

    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: '/products-section/products/' + id
    }).done(function (response) {
        toastr.clear();
        $('#product_' + id).remove();
    });
}

/*******************************
 Product Variations Section
 *******************************/
/*Update via ajax the Attributess on Product*/
function updateProductAttibutes(product_id)
{
    if (confirm('Updating the atributes to this poduct will affect all the related variations.\nProceed?') === false)
        return false;
    
    toastr.info('Please wait updating attributes...');

    var attr_count = $("#product_attributes :selected").length;
    var attributes = $('#product_attributes').val();
    
    if(attr_count == 0)
        $('#new_variation').addClass('hidden');
    else
        $('#new_variation').removeClass('hidden');
    
    jQuery.ajax({
        method: "POST",
        url: "/products-section/variations/attributes",
        data: {product_id: product_id,
            attributes: attributes}
    }).done(function (data) {

        toastr.clear();

        $("#variations_list").replaceWith(data);
        //init the new added in DOM for selects 
        initChosen();
    });
}

/*Save via ajax Variation on Product*/
function updateVariation(variation_id)
{
    toastr.info('Please wait updating attributes...');

    var data = $('.js_variation_block_' + variation_id);
    //var data = $('#variation_form_' + variation_id).serialize();

    jQuery.ajax({
        method: "PUT",
        url: "/products-section/variations/" + variation_id,
        data: data
    }).done(function (data) {

        toastr.clear();

        $("#variation_" + variation_id).replaceWith(data);
        //init the new added in DOM for selects 
        initChosen();
    });
}

/*Add New Variation to Product*/
function addNewVariation(product_id)
{
    toastr.info('Please wait...');

    $.get('/products-section/variations/create?product_id=' + product_id, function (response) {
        toastr.clear();
        $('#variations_list').prepend(response);
        initChosen();
    });
}

/*Save via ajax the New Created Variation on Product*/
function saveVariation(variation_id)
{
    toastr.info('Please wait...');

    var data = $('.js_variation_block_' + variation_id);
    //var data = $('#variation_form_' + variation_id).serialize();

    jQuery.ajax({
        method: "POST",
        url: "/products-section/variations",
        data: data
    }).done(function (data) {

        toastr.clear();

        $("#variation_" + variation_id).replaceWith(data);
        //init the new added in DOM for selects 
        initChosen();
    });
}

/*Save the new Created Variation on Product*/
function deleteVariation(variation_id)
{
    if (confirm("Are you sure to delete this product variation?") === false)
        return false;


    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: "/products-section/variations/" + variation_id,
        data: []
    }).done(function (data) {

        toastr.clear();

        $("#variation_" + variation_id).remove();
    });
}
/*******************************
 Product Images Section
 *******************************/
/*upload image for product via ajax */

function uploadProductImage(id)
{
    var form = new FormData($("#product_image_form_" + id)[0]);
    var error_field = $("#product_image_form_" + id + ' .single.fields .warning.field');

    toastr.info('Please wait...');

    //hide the warning message
    error_field.hide();

    jQuery.ajax({
        url: '/products-section/product-images',
        type: 'POST',
        data: form,
        contentType: false,
        cache: false,
        processData: false,
        success: function (data) {
            if (data.error !== undefined) {
                toastr.clear();
                //show warning message for wrong image dimension
                error_field.show();
                return false;
            } else {
                //clear the form
                $("#product_image_form_" + id)[0].reset();
                error_field.hide();
                //append the image to view
                $("#product_images_box").append(data);
                toastr.clear();
                toastr.success('Upload successful!');
                setMainImage();
            }
        }
    });

}

function deleteProductImage(id)
{
    if (confirm("Are you sure to delete this product image?\n This action is not undoable.") === false)
        return false;

    toastr.info('Please wait...');

    jQuery.ajax({
        url: '/products-section/product-images/' + id,
        type: 'DELETE',
        data: [],
        success: function (data) {
            //clear the image from the dom
            $("#product_image_block_" + id).remove();
            toastr.clear();
            setMainImage();
        }
    });
}

function setAsMainProductImage(id)
{
    if ($("#set_as_main_" + id).is(":checked"))
    {
        toastr.info('Please wait...');

        jQuery.ajax({
            url: '/products-section/product-images/' + id + '',
            type: 'PUT',
            data: {'main': 1},
            success: function (data) {
                toastr.clear();
            }
        });
    }
}

function sortProductImage()
{
    toastr.info('Please wait...');

    var arr = jQuery.map($("#product_images_box > .column"), function (d) {
        return $(d).attr('id');
    });
    jQuery.ajax({
        url: '/products-section/product-images/0', //doesn't metter the id, just for url format 
        type: 'PUT',
        data: {'sorting': arr},
        success: function (data) {
            toastr.clear();
        }
    });
}

function setMainImage() {

    if ($('.js_main_image').length > 0) {
        if ($('.js_main_image:checked').length === 0) {
            $('.js_main_image')[0].click();
        }
    }
}

/*******************************
 Product Orders Section
 *******************************/
/*Add Product to Order*/
function addProductToOrder(id)
{
    $.get("/orders-section/order-products/create/?id=" + id, function (response) {
        toastr.clear();
        initChosen();
        $("#products_container").prepend(response);
        initFormValidation();
    });

}

/*Enable/Disable ShippingAddress dropdown on Order Edit*/
function checkShippingAddress()
{
    if ($('#billing_check:checkbox:checked').length > 0) {
        $('#shipping_address_id').val('').trigger('chosen:updated');
        $('#shipping_address_id').prop("disabled", true).trigger("chosen:updated");
    } else
        $('#shipping_address_id').prop("disabled", false).trigger("chosen:updated");
}

/*Update Product in OrderProduct*/
function updateProductOrder(id)
{
    toastr.info('Please wait...');
    var order_id = $('#order_id').val();
    var data = $('#product_form_' + id).serializeArray();
    data.push({name: "order_id", value: order_id});

    jQuery.ajax({
        method: "PUT",
        url: "/orders-section/order-products/" + id,
        data: data
    }).done(function (data) {

        toastr.clear();
        $("#product_form_" + id).replaceWith(data);
        toastr.success('Order updated!');
        //init the new added in DOM for selects 
        initChosen();
    });
}

/*Save New Product in OrderProduct*/
function saveProductOrder(id)
{
    $('#product_form_' + id).form('validate form');
    if ($('#product_form_' + id).form('is valid')) {
        toastr.info('Please wait...');
        var order_id = $('#order_id').val();
        var data = $('#product_form_' + id).serializeArray();
        data.push({name: "order_id", value: order_id});

        jQuery.ajax({
            method: "POST",
            url: "/orders-section/order-products",
            data: data
        }).done(function (data) {

            toastr.clear();
            $("#product_form_" + id).replaceWith(data);
            toastr.success('Order updated!');
            //init the new added in DOM for selects 
            initChosen();
        });
    }
}

/*Delete Product from OrderProduct*/
function deleteProductOrder(id)
{
    if (confirm("Are you sure to delete this product from order?") === false)
        return false;


    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: "/orders-section/order-products/" + id,
        data: []
    }).done(function (data) {

        toastr.clear();
        toastr.success('Order updated!');
        $("#product_form_" + id).remove();
    });
}

/*Update Order*/
function updateOrder(id)
{
    toastr.info('Please wait...');
    var data = $('#orders_form_' + id).serializeArray();

    jQuery.ajax({
        method: "PUT",
        url: "/orders-section/orders/" + id,
        data: data
    }).done(function (data) {

        toastr.clear();
        $("#orders_form_" + id).replaceWith(data);
        //init the new added in DOM for selects 
        initChosen();
    });
}

//delete order
function deleteOrder(id)
{
    if (confirm("Are you sure to delete this order?") === false)
    return false;


    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: "/orders-section/orders/" + id,
        data: []
    }).done(function (data) {

        toastr.clear();
        toastr.success('Order deleted!');
        $("#order_" + id).remove();    
    });
}


/*Add Order Comment*/
function addOrderComment()
{
    var data = $('#order_comment_form').serializeArray();
    jQuery.ajax({
        method: "POST",
        url: "/orders-section/order-products/addOrderComment",
        data: data
    }).done(function (data) {

        toastr.clear();
        $("#comments_container").append(data);
        //init the new added in DOM for selects 
        initChosen();
    });
}

/*******************************
 Suppliers Section
 *******************************/
/*Save Supplier*/
function saveSupplier()
{
    $('#suppliers_form').form('validate form');
    if ($('#suppliers_form').form('is valid')) {
        var form = $('#suppliers_form').serialize();
        $.post('/suppliers-section/suppliers', form, function (response) {
            $('#suppliers_listing tbody').prepend(response);
            //clear form inputs
            $('#suppliers_form')[0].reset();
            toastr.info('New supplier created!');
        });
    }
}

/*Delete Supplier*/
function deleteSupplier(id)
{
    var answer = confirm("Are you sure you want to delete from the database?");
    if (answer)
    {
        $.ajax({
            url: '/suppliers-section/suppliers/' + id,
            type: 'DELETE',
            success: function (data) {
                $('#supplier_' + data).remove();
                toastr.info('Supplier deleted!');
            }
        });
    }
}

/*Validates commission inputs on Supplier Edit Form*/
function validateSupplierCommission() {
    var confirmed = true;
    
    //get the suppier edit form
    $form = $('#suppliers_form_edit');
    
    //get through commission fields
    $('#suppliers_form_edit .commission').each(function(){
        $input = $(this);
        //verify if any of the inputs value has been changed
        if($input.val() != $input.data('old')) {
            //get the placeholder name of the input
            var placeholder  = $input.attr('placeholder');
            //confirmation text
            if(!confirm('Are you sure you want to change '+ placeholder +' rate? This will affect all the current supplier products!')) {
                confirmed = false;
                //replace with the old input value if not confirmed
                $input.val($input.data('old'));
            } 
        }
    });

    //validation for taxable goods field
    if($('#taxable').val() != $('#taxable').data('old')) {
        if(!confirm('Are you sure you want to change taxable goods? This change will affect all the current supplier products!')) {
            confirmed = false;
            
            $('#taxable option[value='+$('#taxable').data('old')+']').prop('selected', true).trigger('chosen:updated');
        } 
    }
        
    //return false if confirmation is canceled
    if(!confirmed) {
        return false;
    }
    
    //submit the form
    $form.submit();
}

/*Add New Contact to SupplierContacts*/
function addSupplierContact(supplier_id)
{
    toastr.info('Please wait...');

    $.get('/suppliers-section/contacts/create/?supplier_id=' + supplier_id, function (response) {
        toastr.clear();
        $('#contacts_container').prepend(response);
    });
}

/*Save New Contact in SupplierContacts*/
function saveSupplierContact(id)
{
    toastr.info('Please wait...');
    var data = $('#contact_form_' + id).serializeArray();

    jQuery.ajax({
        method: "POST",
        url: "/suppliers-section/contacts",
        data: data
    }).done(function (data) {
        toastr.clear();
        $("#contact_form_" + id).replaceWith(data);
    });
}

/*Update Contact in SupplierContacts*/
function updateSupplierContact(id)
{
    toastr.info('Please wait...');
    var data = $('#contact_form_' + id).serializeArray();

    jQuery.ajax({
        method: "PUT",
        url: "/suppliers-section/contacts/" + id,
        data: data
    }).done(function (data) {
        toastr.clear();
        $("#contact_form_" + id).replaceWith(data);
    });
}

/*Delete Contact from SupplierContacts*/
function deleteSupplierContact(id)
{
    if (confirm("Are you sure to delete this contact from supplier?") === false)
        return false;

    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: "/suppliers-section/contacts/" + id,
        data: []
    }).done(function (data) {
        toastr.clear();
        $("#contact_form_" + id).remove();
    });
}

/*******************************
 CMS Users Section
 *******************************/
/*Add CMS User*/
function addCmsUser()
{
    $('#cms_users_form').form('validate form');
    
    if ($('#cms_users_form').form('is valid')) {
        var data = $('#cms_users_form').serializeArray();
        jQuery.ajax({
            method: "POST",
            url: "/cms-users",
            data: data
        }).done(function(data) {
            toastr.clear();
            $("#cms_users_listing").prepend(data);
        });
    }
}

/*Delete User*/
function deleteCmsUser(id)
{
    if (confirm("Are you sure to delete this user?") === false)
        return false;

    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: "/cms-users/" + id,
        data: []
    }).done(function (data) {
        toastr.clear();
        $("#cms_user_" + id).remove();
    });
}

/*******************************
 Users Section
 *******************************/
/*Add User*/
function addUser()
{
    $('#users_form').form('validate form');
    if ($('#users_form').form('is valid')) {
        var data = $('#users_form').serializeArray();
        jQuery.ajax({
            method: "POST",
            url: "/customers",
            data: data
        }).done(function (data) {
            $('#users_form')[0].reset();
            toastr.clear();
            $("#users_listing").prepend(data);

        });
    }
}

/*Delete User*/
function deleteUser(id)
{
    if (confirm("Are you sure to delete this customer?") === false)
        return false;

    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: "/customers/" + id,
        data: []
    }).done(function (data) {
        toastr.clear();
        $("#user_" + id).remove();
    });
}
/*
 * edit address
 */
function editUserAddress(address_id){
    
    if(address_id == ''){
        alert('Select or add new address before editing!');
        return false;
    }
    var user_id = $('#user_id').val();
    
    jQuery.ajax({
            method: "GET",
            url: "/customers/"+user_id+"/address/"+address_id
        }).done(function (data) {
            
            toastr.clear();
            $("#address_container").html(data);

        });    

}
/*
 * create address associated
 */
function createUserAddress(type){
    var user_id = $('#user_id').val();
    jQuery.ajax({
            method: "GET",
            url: "/customers/"+user_id+"/address-type/"+type
        }).done(function (data) {            
            toastr.clear();
            $("#address_container").html(data);
        });    

}

function deleteAddress(id) {
    if (confirm("Are you sure to delete this address?") === false)
    return false;
    
    var user_id = $('#user_id').val();
    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: "/customers/"+user_id+"/address/" + id,
        data: []
    }).done(function (data) {
        toastr.clear();
        location.reload();
    });
    
} 


/*******************************
 Reviews Section
 *******************************/
/*Delete Review*/
function deleteReview(id)
{
    if (confirm("Are you sure to delete this review?") === false)
        return false;

    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: "/products-section/reviews/" + id,
        data: []
    }).done(function (data) {

        toastr.clear();

        $("#review_" + id).remove();
    });
}

/*******************************
 Report Schedules Section
 *******************************/
/*Run Schedule*/
function runSchedule(schedule_id)
{
    toastr.info('Please wait...');

    $.get('/reports-section/schedules/' + schedule_id + '/run', function (response) {
        toastr.clear();
        $('#schedule_lastreport_' + schedule_id).html(response.mysql_date);
        toastr.success('Report created!');
    });
}

/*Delete Report*/
function deleteReport(id)
{
    if (confirm("Are you sure to delete this report?") === false)
        return false;

    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: "/reports-section/reports/" + id,
        data: []
    }).done(function (data) {
        toastr.clear();
        $("#report_" + id).remove();
    });
}

/*Delete Schedule*/
function deleteSchedule(id)
{
    if (confirm("Are you sure to delete this schedule report?") === false)
        return false;

    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: "/reports-section/schedules/" + id,
        data: []
    }).done(function (data) {
        toastr.clear();
        $("#schedule_" + id).remove();
    });
}

/*******************************
 Vouchers Section
 *******************************/

/*Save Voucher*/
function saveVoucher()
{
    $('#vouchers_form').form('validate form');
    if ($('#vouchers_form').form('is valid')) {
        if($('#vouchers_form input[name="discount_fixed"]').val() === '')
            $('#vouchers_form input[name="discount_fixed"]').val(0);
        
        if($('#vouchers_form input[name="discount_percent"]').val() === '')
            $('#vouchers_form input[name="discount_percent"]').val(0);
        
        var data = $('#vouchers_form').serializeArray();
        jQuery.ajax({
            method: "POST",
            url: "/orders-section/vouchers",
            data: data
        }).done(function (data) {
            if(data.error){
                errorsHtml = '<ul class="list"><li>'+ data.error + '</li></ul>';
                $('#vouchers_form .error.message').show().html( errorsHtml );
            } else {
                toastr.clear();
                $('#vouchers_form .error.message').hide();
                $("#vouchers_listing tbody").prepend(data);
            }
        });
    }
}

/*Delete Voucher*/
function deleteVoucher(id)
{
    if (confirm("Are you sure to delete this voucher?") === false)
        return false;
    jQuery.ajax({
        method: "DELETE",
        url: "/orders-section/vouchers/" + id,
        data: []
    }).done(function (data) {
        toastr.clear();
        $("#voucher_" + id).remove();
    });
}

/*Validates voucher*/
function validateVoucher(event)
{
    var df = $('input[name=discount_fixed]').val();
    var ms = $('input[name=min_spend]').val();

    //verify if fixed discount is greater than minimum spend
    if(df > ms) {
        event.preventDefault();
        alert('Discount amount is bigger than mininmum spend amount!');
    }
    
}

/*Extra Validates voucher on document ready if submit is applied*/
function initVoucherValidation()
{
    $('#vouchers_form').on('submit', function(e){
        validateVoucher(e);
    });
}

/*Add Voucher Rule*/
function addVoucherRule(voucher_id)
{
    toastr.info('Please wait...');
    $.get('/orders-section/voucher-rules/create/?voucher_id=' + voucher_id, function (response) {
        toastr.clear();
        $('#rules_container').prepend(response);
        initDropdown();
        initFormValidation();
    });
}

/*Save New Rule in VoucherRules*/
function saveVoucherRule(id)
{
    $('#rule_form_' + id).form('validate form');
    if ($('#rule_form_' + id).form('is valid') && $('#reference_id').val()!=='') {
        toastr.info('Please wait...');
        var data = $('#rule_form_' + id).serializeArray();

        jQuery.ajax({
            method: "POST",
            url: "/orders-section/voucher-rules",
            data: data
        }).done(function (data) {
            toastr.clear();
            $("#rule_form_" + id).replaceWith(data);
            initDropdown();
        });

    }
}

/*Update Rule in VoucherRules*/
function updateVoucherRule(id)
{
    initFormValidation();
    $('#rule_form_' + id).form('validate form');
    if ($('#rule_form_' + id).form('is valid')) {
        toastr.info('Please wait...');
        var data = $('#rule_form_' + id).serializeArray();

        jQuery.ajax({
            method: "PUT",
            url: "/orders-section/voucher-rules/" + id,
            data: data
        }).done(function (data) {
            toastr.clear();
            $("#rule_form_" + id).replaceWith(data);
            initDropdown();
        });
    }
}

/*Delete Rule from VoucherRules*/
function deleteVoucherRule(id)
{
    if (confirm("Are you sure to delete this voucher rule from voucher?") === false)
        return false;

    toastr.info('Please wait...');

    jQuery.ajax({
        method: "DELETE",
        url: "/orders-section/voucher-rules/" + id,
        data: []
    }).done(function (data) {
        toastr.clear();
        $("#rule_form_" + id).remove();
    });
}

/*Validation on Voucher Rule save/update*/
function voucherRuleValidation(id)
{
    var result = true;

    if (!$('#rule_form_' + id + ' #rule_type option:selected').val()) {
        alert('Please select a rule type first!');
        toastr.clear();
        result = false;
    } else if ($('#rule_form_' + id + ' #reference_' + id + ' input').val() == '') {
        alert('Please search for a reference!');
        toastr.clear();
        result = false;
    }

    return result;
}

/*******************************
 Locations Section
 *******************************/
/*Save Location*/
function saveLocation()
{
    $('#locations_form').form('validate form');
    if ($('#locations_form').form('is valid')) {
        toastr.info('Please wait...');
        var data = $('#locations_form').serializeArray();
        $.post('/products-section/locations', data, function (response) {
            toastr.clear();
            $('#locations_listing').prepend(response);
        });
    }
}

/*Delete Location*/
function deleteLocation(id)
{
    if (confirm("Are you sure to delete this location?") === false)
        return false;
    jQuery.ajax({
        method: "DELETE",
        url: "/products-section/locations/" + id,
        data: []
    }).done(function (data) {
        toastr.clear();
        $("#location_" + id).remove();
    });
}


/*******************************
 Content Section
 *******************************/

/*Delete Voucher*/
function deleteContent(id)
{
    if (confirm("Are you sure to delete this content?") === false)
        return false;
    jQuery.ajax({
        method: "DELETE",
        url: "/contents-section/contents/" + id,
        data: []
    }).done(function (data) {
        toastr.clear();
        $("#content_" + id).remove();
    });
}

/*******************************
 Slider Images Section
 *******************************/

/*Upload slider image via ajax */
function uploadSliderImage(id)
{
    var form = new FormData($("#slider_form_" + id)[0]);
    var error_field = $("#slider_form_new .single.fields .warning.field");
    error_field.hide();
    $('#js_slider_warning').html('');
    
    toastr.info('Please wait...');

    jQuery.ajax({
        url: '/contents-section/sliders',
        type: 'POST',
        data: form,
        contentType: false,
        cache: false,
        processData: false,
        success: function (data) {
            if (data.error !== undefined) {
                toastr.clear();
                //show warning message for wrong image dimension
                $('#js_slider_warning').html(data.error);
                error_field.show();
                //clear the form
                $("#slider_form_" + id)[0].reset();
                return false;
            } else {
                //clear the form
                $("#slider_form_" + id)[0].reset();
                //append the image to view
                $("#sliders_images_box").append(data);
                toastr.clear();
                toastr.success('Upload successful!');
            }
        }
    });
}

/*Sorting slider images*/
function sortSliderImage()
{
    toastr.info('Please wait...');

    var arr = jQuery.map($("#sliders_images_box > .column"), function (d) {
        return $(d).attr('id');
    });
    jQuery.ajax({
        url: '/contents-section/sliders/0', //doesn't metter the id, just for url format 
        type: 'PUT',
        data: {'sorting': arr },
        success: function(data){
            toastr.clear();
        }
    });
}

/*Delete slider*/
function deleteSlider(id){
    if (confirm("Are you sure to delete this slider?\n This action is not undoable.") === false)
        return false;

    toastr.info('Please wait...');

    jQuery.ajax({
        url: '/contents-section/sliders/' + id,
        type: 'DELETE',
        data: [],
        success: function(data){
            //clear the image from the dom
            $("#slider_block_"+id).remove();
            toastr.clear();
            //setActiveSlider();
        }
    });
}

function setAsActiveSlider(id) {
    
    var active = 0;
    if( $("#set_active_"+id).is(":checked"))
    {
        active = 1;
    }
   
    toastr.info('Please wait...');

    jQuery.ajax({
        url: '/contents-section/sliders/' + id,
        type: 'PUT',
        data: {'active': active},
        success: function (data) {
            toastr.clear();
        }
    });
}

function saveSliderUrl(id) {
   
    toastr.info('Please wait...');
    
    var url = $('#url_'+id).val();
    
    jQuery.ajax({
        url: '/contents-section/sliders/' + id,
        type: 'PUT',
        data: {'url': url},
        success: function (data) {
            toastr.clear();
        }
    });
}
